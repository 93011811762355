.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    padding: 10px 20px;
  }
  
  .nav-logo img {
    height: 70px;
  }
  
  .nav-logo a {
    display: flex;
    align-items: center;
  }
  
  .nav-logo img {
    max-height: 100%;
  }
  
  .nav-links {
    display: flex;
    list-style: none;
    gap: 20px;
  }
  
  .nav-links li a {
    color: #fff;
    text-decoration: none;
  }
  
  .nav-hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  .nav-hamburger .line {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 4px 0;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .nav-links {
      position: fixed;
      top: 0;
      right: -100%;
      height: 100vh;
      width: 200px;
      background-color: #333;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: right 0.3s ease-in-out;
    }
  
    .nav-links.open {
      right: 0;
    }
  
    .nav-hamburger {
      display: flex;
    }
  }
  